<template>
    <div class="bg-light">
        <div class="container">
            <div class="row">
                <div class="col-12 d-flex justify-content-center align-items-center" style="height: 100vh;">
                    <div class="text-center" style="width: 100%">
                        <img class="mb-4" src="@/assets/img/bau-logo-for-light.svg"/>
                        <h3 class="mb-4">{{ $t('bahcesehir_university') }}</h3>
                        <b-row class="mt-4">
                            <b-col md="6" class="mt-4"><h3 class="text-md-right text-center">{{ $t('document_verify') }}</h3></b-col>
                            <b-col md="6" class="mt-4">
                                <b-alert v-if="fileExists" class="text-left" show variant="danger">
                                    <i class="ri-information-fill" style="font-size: 16px"></i>&nbsp;
                                    <span>{{ fileExists }}</span>
                                </b-alert>
                                <b-alert v-if="verifiedFile" class="text-left" show variant="success">
                                    <i class="ri-shield-check-line" style="font-size: 16px"></i>&nbsp;
                                    <span>"{{ verifiedFile }}" </span>
                                    <span v-if="downloadCheck">{{ $t('downloaded') }}</span>
                                    <span v-if="!downloadCheck">{{ $t('document_verified') }}</span>
                                </b-alert>
                            </b-col>
                        </b-row>
                        <b-row v-if="verifiedFile">
                            <b-col md="12" class="d-flex justify-content-center align-items-center">
                                <b-button v-if="!downloadCheck" @click="download" variant="primary">{{$t('document_download')}}</b-button>
                                <b-button v-if="downloadCheck" variant="success">
                                    <i class="ri-check-line" style="font-size: 32px"></i>
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import QRVerificationService from "@/services/QRVerificationService";

export default {
    components: {},
    metaInfo() {
        return {
            title: this.$t('document_verify')
        }
    },
    data() {
        return {
            fileExists: null,
            verifiedFile: null,
            downloadCheck: false
        }
    },
    methods: {
        getData() {
            QRVerificationService.get(this.$route.params.filename).then(response => {
                let start = response.data.indexOf('"') + 1
                let end = response.data.indexOf('"', start)
                let filename = response.data.substr(start, end - start)
                this.verifiedFile = filename
            }).catch(e => {
                this.fileExists = this.$t('api.' + e.data.message)
            })
        },

        download(){
            QRVerificationService.get(this.$route.params.filename, 'download').then(response => {
               this._downloadFile(response, this.verifiedFile)
                this.downloadCheck = true
            }).catch(e => {
                this.fileExists = this.$t('api.' + e.data.message)
            })
        }
    },

    created() {
        this.getData()
    }
}
</script>

